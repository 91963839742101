import commonmarkPreset from "markdown-it/lib/presets/commonmark";

import markdownIt from "markdown-it";
import subscriptPlugin from "markdown-it-sub";
import superscriptPlugin from "markdown-it-sup";
import tablePlugin from "markdown-it-multimd-table";
import linkifyImagesPlugin from "markdown-it-linkify-images";
import linkAttributesPlugin from "markdown-it-link-attributes";
import alignPlugin from "markdown-it-align";
import checkboxPlugin from "markdown-it-task-checkbox";

const md = markdownIt({
  ...commonmarkPreset.options,
  html: false,
  linkify: true,
});

md.use(subscriptPlugin)
  .use(superscriptPlugin)
  .use(tablePlugin)
  .use(linkAttributesPlugin, {
    attrs: {
      target: "_blank",
    },
  })
  .use(linkifyImagesPlugin, {
    target: "_blank",
  })
  .use(alignPlugin)
  .use(checkboxPlugin, {
    disabled: true,
    liClass: "flex items-center gap-2 pointer-events-none -ml-10",
    ulClass: "list-none",
  });

const parse = (markdown) => {
  return md.render(markdown || "");
};

export default parse;
