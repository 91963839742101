import React from "react";
import parse from "./utils/markdownit";

function ListView({ data, renderValues }) {
  return (
    <div className="">
      {data.length > 0 && (
        <div className="mt-8 flex flex-col px-10 mx-autooverflow-auto">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                <table className="table-fixed min-w-full border-separate">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="sticky top-0 z-10 t-40 border-b whitespace-nowrap first-letter:border-gray-300 bg-gray-50 bg-opacity-75 p-2 text-left text-sm backdrop-blur backdrop-filter font-semibold text-gray-900 max-w-xs overflow-x-auto"
                      >
                        Sl.
                      </th>
                      {renderValues.map((item, index) => {
                        return (
                          <th
                            scope="col"
                            className="sticky top-0 z-10 border-b whitespace-nowrap first-letter:border-gray-300 bg-gray-50 bg-opacity-75 px-4 py-2 text-left text-sm backdrop-blur backdrop-filter font-semibold text-gray-900 max-w-xs overflow-x-auto overflow-y-scroll"
                            key={index}
                          >
                            <div
                              className="max-h-28"
                              dangerouslySetInnerHTML={{
                                __html: parse(item),
                              }}
                            ></div>
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((row, i) => (
                      <tr key={i}>
                        <td className="border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 max-w-xs overflow-x-auto">
                          {i + 1}
                        </td>
                        {renderValues.map((item, index) => {
                          return (
                            <td
                              className="border-b border-gray-200 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8 max-w-xs overflow-x-auto"
                              key={index}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: parse(row[item]),
                                }}
                              ></div>
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ListView;
