import React from "react";
import SummaryItem from "./SummaryItem";

function Summary({ renderValues, flatObject }) {
  return (
    renderValues.length > 0 && (
      <div className="max-w-5xl mx-auto pb-40 px-4">
        <h1 className="text-3xl mt-4 font-semibold">Summary</h1>
        {renderValues.map((question, index) => {
          return (
            <SummaryItem
              question={question}
              flatObject={flatObject}
              key={index}
            />
          );
        })}
      </div>
    )
  );
}

export default Summary;
