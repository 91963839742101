import React from "react";
import { usePapaParse } from "react-papaparse";

export default function JsonToCSV({ data }) {
  const { jsonToCSV } = usePapaParse();

  const handleJsonToCSV = () => {
    const csv = jsonToCSV(data);

    var csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, "download.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }

    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "download.csv");
    tempLink.click();
  };

  return (
    <button
      type="button"
      className="inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-primary-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
      onClick={() => handleJsonToCSV()}
    >
      Download CSV
    </button>
  );
}
