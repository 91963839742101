import React from "react";
import CSVReader from "./CSVReader";
import { MultiSelect } from "react-multi-select-component";
import ListView from "./ListView";
import Summary from "./Summary";
import JsonToCSV from "./jsonToCsv";

let updateData = (state, setState, data) => {
  let columns = Object.keys(data.at(-1));
  let flatObject = {};
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (flatObject[key] === undefined) {
        flatObject[key] = [];
      }
      flatObject[key].push(item[key]);
    });
  });
  setState({
    ...state,
    data: data,
    columns: columns,
    columnsToShow: columns,
    flatObject: flatObject,
  });
};

function App() {
  let [state, setState] = React.useState({
    data: [],
    columns: [],
    columnsToShow: [],
    view: "summary",
    flatObject: {},
  });

  return (
    <div className="">
      <div className="fixed inset-x-0 bottom-0 bg-primary-500 text-white flex-1 border-t z-40">
        <div className="flex justify-between items-center p-4">
          <div className="max-w-lg">
            <CSVReader
              updateDataCB={(data) => updateData(state, setState, data)}
            />
          </div>
          {state.data.length > 0 && (
            <>
              <div>
                <button
                  onClick={() =>
                    setState({
                      ...state,
                      view: state.view === "summary" ? "list" : "summary",
                    })
                  }
                  type="button"
                  className="mr-4 inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-primary-700 shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  {state.view === "summary" ? "Show List" : "Show Summary"}
                </button>
              </div>
              <div className="flex flex-col items-end">
                <h3 className="pb-1">{state.data.length} Submissions Loaded</h3>
                <JsonToCSV data={state.data} />
              </div>
            </>
          )}
        </div>
      </div>

      {state.data.length > 0 && (
        <div className="fixed inset-x-0 top-0 flex-1 border-t z-40">
          <div className="max-w-xs p-2">
            <MultiSelect
              options={state.columns.map((item) => ({
                label: item,
                value: item,
              }))}
              value={state.columnsToShow.map((item) => ({
                label: item,
                value: item,
              }))}
              onChange={(val) =>
                setState({
                  ...state,
                  columnsToShow: val.map((item) => item.value),
                })
              }
              labelledBy="Select Columns"
            />
          </div>
        </div>
      )}
      <div>
        {state.view === "list" && (
          <ListView data={state.data} renderValues={state.columnsToShow} />
        )}
        {state.view === "summary" && (
          <Summary
            data={state.data}
            renderValues={state.columnsToShow}
            flatObject={state.flatObject}
          />
        )}
      </div>
    </div>
  );
}

export default App;
