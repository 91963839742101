import React from "react";
import parse from "./utils/markdownit";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Pie,
  PieChart,
  ResponsiveContainer,
  Cell,
} from "recharts";
function SummaryItem({ question, flatObject }) {
  let [view, setView] = React.useState("list");
  var counts = flatObject[question].reduce((p, name) => {
    if (!p.hasOwnProperty(name)) {
      p[name] = 0;
    }
    p[name]++;
    return p;
  }, {});

  let dataForPlots = Object.keys(counts)
    .map((key) => ({
      name: key,
      count: counts[key],
    }))
    .sort((a, b) => b.count - a.count);

  let selectedClass = (value) => {
    if (view === value) {
      return "px-4 border-r-2 border-gray-400 text-primary-500";
    } else {
      return "px-4 border-r-2 border-gray-400";
    }
  };

  const COLORS = [
    "#9333ea",
    "#f59e0b",
    "#22c55e",
    "#0ea5e9",
    "#ef4444",
    "#8b5cf6",
    "#14b8a6",
    "#f97316",
    "#fb7185",
  ];

  return (
    <div>
      {flatObject[question] && flatObject[question].length > 0 && (
        <div className="bg-gray-50 mt-4 rounded-xl border">
          <div className="border-b bg-gray-100 text-md font-semibold text-gray-700 rounded-t-xl ">
            <div
              className="px-4 pt-4 pb-2"
              dangerouslySetInnerHTML={{
                __html: parse(question),
              }}
            ></div>
            {dataForPlots.length < 16 && (
              <div className="py-2 bg-gray-200">
                <button
                  onClick={() => setView("list")}
                  type="button"
                  className={selectedClass("list")}
                >
                  List View
                </button>

                <button
                  onClick={() => setView("bar")}
                  type="button"
                  className={selectedClass("bar")}
                >
                  Bar Chart
                </button>

                <button
                  onClick={() => setView("pie")}
                  type="button"
                  className={selectedClass("pie")}
                >
                  Pie Chart
                </button>
              </div>
            )}
          </div>

          <div className="">
            {
              {
                list: (
                  <div className="overflow-y-auto max-h-96 p-4">
                    {" "}
                    {dataForPlots.map((data, i) => {
                      return (
                        <div
                          className="flex justify-between items-center p-4 border-b "
                          key={i}
                        >
                          <div
                            className="text-md  text-gray-700"
                            dangerouslySetInnerHTML={{
                              __html: parse(data.name || "_NA_"),
                            }}
                          ></div>
                          <div className="text-md font-semibold text-gray-700">
                            {data.count}
                            <span className="text-sm pl-4">
                              {Math.round(
                                (data.count / flatObject[question].length) * 100
                              )}
                              %
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ),
                pie: (
                  <ResponsiveContainer width="100%" aspect={16.0 / 9.0}>
                    <PieChart width={1000} height={400}>
                      <Pie
                        dataKey="count"
                        isAnimationActive={false}
                        data={dataForPlots}
                        cx="50%"
                        cy="50%"
                        label
                      >
                        {dataForPlots.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />

                      {dataForPlots.length < 10 && <Legend />}
                    </PieChart>
                  </ResponsiveContainer>
                ),
                bar: (
                  <ResponsiveContainer width="100%" aspect={16.0 / 9.0}>
                    <BarChart width={700} height={400} data={dataForPlots}>
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="count" fill="#976ae2">
                        {dataForPlots.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                ),
              }[view]
            }
          </div>
        </div>
      )}
    </div>
  );
}

export default SummaryItem;
